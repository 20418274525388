import { toast } from '@/shared/utils/UIHelper'

export default {
  name: 'NewRegisterMessageSms',

  props: {
    placeholderTitle: String,
    placeholderMessage: String,
    title: String,
    data: Object,
    isNew: Boolean,
  },

  computed: {
    disable() {     
      if (this.isNew === true) {
        if (!this.data.title || !this.data.message)
          return true
        else {
          if (this.data.title && this.data.message)
          return false
        }

      } else {
          if (this.data.title && this.data.message) {
            return false
        }
      }
      return true
    }
  },

  methods: {
    backTo() { this.$router.go(-1) },

    stringByteCount(s) { return encodeURI(s).split(/%..|./).length - 1 },

    save() {
      const currentStringByteCount = this.stringByteCount(this.data.message)
      if (currentStringByteCount > 140) {
        toast.info('Peso da mensagem acima do limite', 'PESO DA MENSAGEM')
        return
      }

      this.isNew ? this.$emit('create', this.data) : this.$emit('update', this.data) 
    },
  }
}