import MessageSmsRepository from '@/shared/http/repositories/socialProject/message-sms'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegisterMessageSms from '@/components/layout/NewRegisterMessageSms/NewRegisterMessageSms.vue'
import MessageSmsModel from '@/shared/models/messageSms'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'NovaMensagemSms',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegisterMessageSms.name]: NewRegisterMessageSms
  },

  data: () => ({
    placeholderTitle: 'Ex: Faltas',
    placeholderMessage: 'Ex: ABDA: ....',
    title: 'Mensagem SMS',
    MessageSmsModel: new MessageSmsModel(),
    isNew: true,
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Mensagem SMS',
            href: this.$router.resolve({ name: 'MessageSms' }).href
          },
          {
            text: 'Nova Mensagem',
            active: true
          }
        ]
      }
    }
  },

  created() {
  },

  methods: {
    create(data) {
      data.title = data.title.toUpperCase()
      data.message = data.message.toUpperCase()
      
      loading.push()
      MessageSmsRepository.Create(data)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Mensagem criada com sucesso', 'NOVA MENSAGEM')
          Promise.resolve()
          return this.$router.replace({ name: 'MessageSms' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar mensagem', 'ERRO')
        })
    }
  }
}
